

$(function(){

    $("#livesearch").keyup(function () {
        e.ajax({
            type: "POST",
            url: "ajax.php",
            data: "&livesearch=" + e(this).val(),
            success: function (t) {
                "" != t && (e("#ls_result").html(t), e("#ls_result").css("display", "block"));
            },
        });
    });

});

